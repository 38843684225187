
/*
|--------------------------------------------------------------------------
| Store Implementation
|--------------------------------------------------------------------------
*/

export default {
  namespaced: true,

  state: {
    items: [],
    extras: []
  },

  mutations: {
    setItems(state, items) {
      state.items = [];

      for (var i = 0; i < items.length; i++) {
        let item = items[i];

        state.items.push(item);
      }
    },

    setExtras(state, items) {
      state.extras = [];

      for (var i = 0; i < items.length; i++) {
        let item = items[i];

        state.extras.push(item);
      }
    },

    addItem(state, item) {
      // Check if product exists
      var itemExists = false;
      for (var i = 0; i < state.items.length; i++) {
        let currentItem = state.items[i];

        if (item.product.productId == currentItem.product.productId) {
          itemExists = true;

          let maxQuantity = currentItem.product.stock;
          let newQuantity = parseInt(item.quantity);

          if (currentItem.quantity + newQuantity <= maxQuantity) {
            currentItem.quantity = currentItem.quantity + newQuantity;
          } else {
            currentItem.quantity = maxQuantity;
          }
        }
      }

      if (!itemExists) {
        state.items.push(item);
      }

      // Environment fee
      if (item.product.type.name == 'Däck') {
        var environmentFeeExists = false;
        for (var i = 0; i < state.items.length; i++) {
          let currentItem = state.items[i];

          if (parseInt(currentItem.product.productId) == 94125204) {
            environmentFeeExists = true;
            currentItem.quantity = currentItem.quantity + parseInt(item.quantity);
            currentItem.balancingPrice = 0;
          }
        }

        if (!environmentFeeExists) {
          let environmentProduct = {
            quantity: item.quantity,
            product: {
              productId: 94125204,
              name: 'Miljöavgift',
              consumerPrice: 2000,
              price: 2000,
              price: 500,
              type: {
                id: 99999999,
                name: 'Miljöavgift'
              }
            }
          };

          state.items.push(environmentProduct);
        }
      }

      localStorage.cart_items = JSON.stringify(state.items);
    },

    removeItem(state, item) {
      var removeIndex = null;

      for (var i = 0; i < state.items.length; i++) {
        let cartItem = state.items[i];

        if (this.extra) {
          if (cartItem.product.name == item.name) {
            removeIndex = i;
          }
        } else {
          if (cartItem.product.id == item.id) {
            removeIndex = i;
          }
        }
      }

      state.items.splice(removeIndex, 1);

      var tyreQuantity = 0;

      for (var i = 0; i < state.items.length; i++) {
        let cartItem = state.items[i];

        if (cartItem.product.type.name == 'Däck') {
          tyreQuantity += cartItem.quantity;
        }
      }

      if (tyreQuantity > 0) {
        for (var i = 0; i < state.items.length; i++) {
          let cartItem = state.items[i];

          if (cartItem.product.productId == 94125204) {
            cartItem.quantity = tyreQuantity;
          }
        }
      }

      if (tyreQuantity == 0) {
        var removeEnvironmentIndex = null;

        for (var i = 0; i < state.items.length; i++) {
          let cartItem = state.items[i];

          if (cartItem.product.productId == 94125204) {
            removeEnvironmentIndex = i;
          }
        }

        state.items.splice(removeEnvironmentIndex, 1);
      }

      localStorage.cart_items = JSON.stringify(state.items);
    },

    updateItemQuantity(state, quantity) {
      if (quantity.quantity < 1) {
        quantity.quantity = 1;
      }
      var tyreQuantity = 0;

      for (var i = 0; i < state.items.length; i++) {
        let cartItem = state.items[i];

        if (cartItem.product.id == quantity.product.id) {
          cartItem.quantity = quantity.quantity;
        }

        if (cartItem.product.productId == 94125204) {
          cartItem.quantity = quantity.quantity;
        }

        if (cartItem.product.type.name == 'Däck') {
          tyreQuantity += cartItem.quantity;
        }
      }

      if (tyreQuantity > 0) {
        for (var i = 0; i < state.items.length; i++) {
          let cartItem = state.items[i];

          if (cartItem.product.productId == 94125204) {
            cartItem.quantity = tyreQuantity;
          }
        }
      }

      localStorage.cart_items = JSON.stringify(state.items);
    },

    updateItemBalancingState(state, balancing) {
      for (var i = 0; i < state.items.length; i++) {
        let cartItem = state.items[i];

        if (cartItem.product.id == balancing.product.id) {
          cartItem.balancingState = balancing.balancingState;
        }
      }

      localStorage.cart_items = JSON.stringify(state.items);
    },

    updateItemBalancingPrice(state, price) {
      for (var i = 0; i < state.items.length; i++) {
        let cartItem = state.items[i];

        if (cartItem.product.id == price.product.id) {
          cartItem.product.balancingPrice = price.price;
        }
      }

      localStorage.cart_items = JSON.stringify(state.items);
    }
  },

  getters: {
    priceTotal(state) {
      var price = 0;

      for (var i = 0; i < state.items.length; i++) {
        let item = state.items[i];

        if (item.extra) {
          price += Math.ceil(item.product.price / 100 * 1.25) * item.quantity;
        } else {
          price += Math.ceil(item.product.consumerPrice / 100 * 1.25) * item.quantity;
        }

        if (item.extra == undefined || item.extra == false) {
          if (item.product.type.id == 1 && item.balancingState == 1) {
            var balancingPrice = 0;

            balancingPrice = item.product.balancingPrice;

            price += item.quantity * balancingPrice;
          }
        }
      }

      return price;
    },

    priceVAT(state, getters) {
      return Math.floor(getters.priceTotal * 0.25);
    },

    totalEnvironmentFee(state, getters) {
      var fee = 0;

      for (var i = 0; i < state.items.length; i++) {
        let currentItem = state.items[i];

        if (currentItem.product.productId == 94125204) {
          fee += (currentItem.quantity * 25);
        }
      }

      return fee;
    }
  }
};
